
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import IntegerControl from "@/components/controls/base/IntegerControl.vue";
import DecimalControl from "@/components/controls/base/DecimalControl.vue";
import TextControl from "@/components/controls/base/TextControl.vue";
import CatalogueSelectControl from "@/components/controls//base/CatalogueSelectControl.vue";
import { getTransportBoxCar } from "../../../../api/transportBoxCarApi";
import { ability } from "@/abilities/ability";

export default defineComponent({
  components: {
    CreateEditDialog,
    IntegerControl,
    DecimalControl,
    TextControl,
    CatalogueSelectControl,
  },
  mixins: [mountComponentMixin],
  props: {
    saveModelCallback: { required: true },
  },
  computed: {
    isCopyMode() {
      return this.copyMode as boolean;
    },
    hasOrder() {
      return ability.can("view", "Modules.Order");
    },
  },
  data() {
    return {
      firstDisplay: true,
      model: null,
      copyMode: false,
    };
  },
  methods: {
    onChange() {
      this.setCopyMode(true);
      getTransportBoxCar(this.model.transportBoxCarId).then((res) => {
        this.setData(res.data);
      });
    },
    setData(data: any) {
      delete data["id"];
      Object.assign(this.model, data);
    },
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      this.dialog().open(model, editType);
      this.model = this.dialog().createEditModel;
      this.firstDisplay = true;
      this.setCopyMode(false);
    },
    close() {
      this.dialog().close();
    },
    save() {
      this.dialog().save();
    },
    setCopyMode(enabled: any) {
      this.copyMode = enabled;
    },
  },
});
